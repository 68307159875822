<div style="margin: 0 auto; height: 1024px; background-image: url('./assets/logo_background.png');">

  <div class="container">

    <br>
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-5">

        <div *ngIf="!token_validado" class="card" style="margin-top: 10px;">
          <div class="card-header bg-success">
            <b>Informe o código recebido no e-mail</b>
          </div>
          <div class="card-body">

            <form #frm="ngForm">

              <div *ngIf="msg_erro_token" class="alert alert-warning" role="alert">
                Código inválido ou já utilizado!
              </div>

              <div *ngIf="msg_erro_server" class="alert alert-warning" role="alert">
                Sem comunicação com o servidor!
              </div>

              <div *ngIf="token_expirado" class="alert alert-warning" role="alert">
                Código de alteração de senha expirado!
              </div>


              <div class="form-group">
                <label for="token">Digite o código</label>
                <input type="text" class="form-control" id="token" name="token" [(ngModel)]="token_recebido" required>
              </div>

              <div class="col-md-6">
                <button [disabled]="!frm.valid" style="margin-top: 30px;" id="btn_alt_senha"
                  class="btn btn-info btn-block" type="submit" (click)="validarToken()">
                  <span *ngIf="spinner_token" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  Validar código</button>
              </div>

            </form>
          </div>

        </div>
      </div>

    </div>


    <div class="row">
      <div class="col-md-3">
      </div>

      <div class="col-md-5">

        <div *ngIf="token_validado" class="card" style="margin-top: 10px;">
          <div class="card-header bg-success">
            <b>Alteração de senha</b>
          </div>
          <div class="card-body">

            <form #frm="ngForm">


              <div *ngIf="senhas_nao_conferem" class="alert alert-warning" role="alert">
                As senhas informadas não conferem!
              </div>

              <div *ngIf="senha_alterada" class="alert alert-success" role="alert">
                Senha alterada com sucesso!
              </div>

              <div *ngIf="msg_erro_server_senha" class="alert alert-warning" role="alert">
                Sem comunicação com o servidor!
              </div>

              <div class="form-group">
                <label for="new_password">Digite a nova senha</label>
                <input type="password" class="form-control" id="new_password" name="new_password"
                  [(ngModel)]="new_password" required>
                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
              </div>
              <div class="form-group">
                <label for="new_password2">Confirme a nova senha</label>
                <input type="password" class="form-control" id="new_password2" name="new_password2"
                  [(ngModel)]="new_password2" required>
              </div>

              <div class="col-md-4">
                <button [disabled]="!frm.valid" style="margin-top: 30px;" id="btn_alt_senha"
                  class="btn btn-info btn-block" type="submit" (click)="alterarSenha()">
                  <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  Alterar senha</button>
              </div>

            </form>


          </div>
        </div>
      </div>

    </div>
  </div>

</div>
