import { Component, OnInit } from '@angular/core';
import { ExtratoService } from '../services/extrato/extrato.service';

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit {

  arquivo: any = null;
  spinner = false;

  constructor(private extratoService: ExtratoService) { }

  ngOnInit(): void {

  }

  buscaArquivo() {
    this.spinner = true;
    this.extratoService.buscaPDF()
      .subscribe((data: Blob) => {

        this.spinner = false;
        console.log(data);

        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL, '_blank');

      }, (erro) => {
        this.spinner = false;
        console.log(erro);
      });
  }

}
