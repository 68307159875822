<aside class="main-sidebar elevation-4 sidebar-dark-success">
  <!-- Brand Logo -->
  <a routerLink="home" class="brand-link navbar-success">
    <img src="assets/logo-portal.png" alt="" class="brand-image img-circle elevation-3" style="opacity: .8">
    <span class="brand-text font-weight-light">Sintef-GO</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="assets/logo-barra-portal.png" style="width:115px; height: 100px; margin-left: 38%;" alt="User Image">
      </div>
      <div class="info">
        <a href="#" class="d-block"></a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu" data-accordion="false">

        <li class="nav-item">
          <a routerLink="home" class="nav-link">
            <i class="nav-icon fas fa-home"></i>
            <p>
              Home
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="grafico" class="nav-link">
            <i class="nav-icon fas fa-chart-pie"></i>
            <p>
              Gráficos
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="extrato" class="nav-link">
            <i class="nav-icon fas fa-hand-holding-usd"></i>
            <p>
              Extrato de descontos
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="convenios" class="nav-link">
            <i class="nav-icon fas fa-building"></i>
            <p>
              Convênios
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="irpf" class="nav-link">
            <i class="nav-icon fab fa-medrt"></i>
            <p>
              Gastos saúde - IRPF
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a href="#" (click)="sair()" class="nav-link">
            <i class="nav-icon fas fa-door-open"></i>
            <p>
              Sair do portal
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-header">LINKS ÚTEIS</li>

        <li class="nav-item">
          <a href="https://sso.gestaodeacesso.planejamento.gov.br/cassso/login" target="_blanc" class="nav-link">
            <i class="nav-icon fas fa-universal-access"></i>
            <p>
              Sigepe
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a href="https://www.dieese.org.br/evento/calculadoraReformaPrevidencia.html" target="_blanc" class="nav-link">
            <i class="nav-icon fas fa-calculator"></i>
            <p>
              Calculadora Dieese
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a href="http://agendaonline.unimedgoiania.coop.br/GUI/VerificaElegibilidade.aspx?p=VGlwb0xvZ2luPTU=" target="_blanc" class="nav-link">
            <i class="nav-icon fas fa-file-medical"></i>
            <p>
              Ag.consultas UNIMED
              <!-- <span class="right badge badge-danger">New</span> -->
            </p>
          </a>
        </li>

        <!-- <li class="nav-item has-treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon far fa-envelope"></i>
            <p>
              Mailbox
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a href="mailbox/mailbox.html" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Inbox</p>
              </a>
            </li>
            <li class="nav-item">
              <a href="mailbox/compose.html" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Compose</p>
              </a>
            </li>
            <li class="nav-item">
              <a href="mailbox/read-mail.html" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Read</p>
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
