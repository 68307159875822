<div class="content-wrapper" style="min-height: 1276px;">
  <div class="container">
    <br>
    <div class="alert alert-info" role="alert">
      Em breve gráficos com estatística de gastos mensais, convênios mais utilizados e outros!
    </div>

    <br>

    <!-- <div class="row">
      <div class="col-md-4">

        <div class="card ">
          <div class="card-header bg-secondary">
            <b>Teste busca pdf</b>
          </div>
          <div class="card-body">

            <form #frm="ngForm">

              <div class="row" style="border: 1px solid; border-color: #eeeeee;">


                <div class="col-md-6">
                  <button [disabled]="!frm.valid" style="margin-top: 30px;" id="btn_buscar"
                    class="btn btn-primary btn-block" (click)="buscaArquivo()">
                    <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Buscar arquivo
                  </button>
                </div>

              </div>

            </form>

          </div>

        </div>

      </div>
    </div> -->

  </div>
</div>
